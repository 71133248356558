import { GAMES, Game } from '@/data.app'
import { isDevApp } from '@/lib/utils'
import { retrieveLaunchParams } from '@telegram-apps/sdk'

export const DEV_GAMES: (Game & { url: string })[] = [
  {
    id: 103,
    name: 'Glass',
    url: 'https://glass-game.lauwake.org?sessionId=' + retrieveLaunchParams().initData?.user?.id,
  },
  {
    id: 104,
    name: 'Flappy Brain',
    url: 'https://thekullapp.github.io/games/flappybird.html?q=1',
  },
  {
    id: 106,
    name: '2048',
    url: 'https://thekullapp.github.io/games/2048.html?q=1',
  },
  {
    id: 107,
    name: 'Basketball',
    url: 'https://game.ioxapp.com/basketball/?q=1',
  },
  {
    id: 108,
    name: 'One Plus Two',
    url: 'https://game.ioxapp.com/oneplustwo/?q=1',
  },
  {
    id: 109,
    name: 'House',
    url: 'https://game.ioxapp.com/house/?q=1',
  },
]

if (isDevApp) {
  GAMES.splice(GAMES.length - 1, 0, ...DEV_GAMES)
}
