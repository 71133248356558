import errorPng from '@/assets/error.png'
import TgSvg from '@/assets/tg.svg?react'
import { postEvent } from '@telegram-apps/sdk'
import { motion } from 'framer-motion'

export function ErrorScreen() {
  return (
    <div
      style={{ background: 'linear-gradient(180deg, #000000 1.04%, #FF8198 100%)' }}
      className="p-8 flex-1 flex flex-col justify-center items-center"
    >
      <img src={errorPng} alt="error" className="object-contain w-3/4" />

      <div className="text-white font-Onest text-lg font-medium text-center">
        Brainy got a little carried away and decided to fix something, everything will work soon.
      </div>

      <div className="absolute left-0 right-0 bottom-[6%] flex flex-col items-center">
        <motion.button
          whileTap={{ scale: 0.9 }}
          className="h-[54px] px-5 gap-2 flex items-center justify-center bg-[#000] rounded-2xl"
          onClick={() => {
            postEvent('web_app_open_tg_link', {
              path_full: '/brainscoin',
            })
          }}
        >
          <TgSvg />
          <span className="font-Onest font-medium">Telegram channel</span>
        </motion.button>

        <div className="mt-3 text-[#FFFFFF80] font-Onest text-xs font-medium">
          Brainy is here :)
        </div>
      </div>
    </div>
  )
}
