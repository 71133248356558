import { useCurrentUserQuery, useReferralsQuery } from '@/api/api'
import AchievementsIcon from '@/assets/settings/achievements.svg?react'
import FriendsIcon from '@/assets/settings/friends.svg?react'
import PromocodeIcon from '@/assets/settings/promocode.svg?react'
import SupportIcon from '@/assets/settings/support.svg?react'
import TasksIcon from '@/assets/settings/tasks.svg?react'
import { MainHeader } from '@/components/MainHeader'
import { SettingsItem } from '@/components/SettingsItem'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { LEAGUES } from '@/data.app'
import { useTasks } from '@/hooks/useTasks'
import { showAd } from '@/lib/adsgram'
import { isFeatureEnabled } from '@/lib/isFeatureEnabled'
import { getFullNameOrUsername } from '@/lib/utils'
import { postEvent, retrieveLaunchParams } from '@telegram-apps/sdk'
import { motion } from 'framer-motion'
import { useLocation } from 'wouter'

export function ProfileTab() {
  const [_, navigate] = useLocation()

  const { initData } = retrieveLaunchParams()

  const { data: currentUser } = useCurrentUserQuery()

  const league = LEAGUES.find((league) => league.id === currentUser?.league_id)

  const { data: referrals } = useReferralsQuery()

  const { isReadyForClaim } = useTasks()

  const hasTasks = isReadyForClaim && Object.values(isReadyForClaim).some((v) => v)

  return (
    <div className="m-4 flex flex-col gap-4">
      <MainHeader />

      <motion.div
        initial={{ scale: 0.7 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring', damping: 20, stiffness: 400 }}
      >
        <div className="flex gap-5 items-center">
          <Avatar className="size-[76px]">
            <AvatarImage src={currentUser?.photo_url} />
            <AvatarFallback>
              {(currentUser?.firstname[0] || '') + (currentUser?.lastname[0] || '')}
            </AvatarFallback>
          </Avatar>

          <div className="flex-1 flex flex-col gap-1">
            <div className="text-xl font-medium ">{getFullNameOrUsername(initData?.user)}</div>

            <div className="flex gap-5">
              {league && (
                <div className="flex justify-center gap-1 -ml-1">
                  <img src={league.infoImage} className="object-contain -m-1 size-[28px]" />

                  <div
                    className="text-sm font-medium"
                    style={{ color: league.color, textShadow: `0 0 10px ${league.color}` }}
                  >
                    {league.name}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </motion.div>

      <div>
        <SettingsItem
          name="Show AD"
          icon={<TasksIcon />}
          onClick={() => {
            showAd('profile_tab')
          }}
        />
        <SettingsItem
          name="Tasks"
          icon={<TasksIcon />}
          onClick={() => {
            navigate('/profile/tasks')
          }}
        >
          {hasTasks && <div className="size-2 bg-accent rounded-full"></div>}
        </SettingsItem>
        <SettingsItem name="Friends" icon={<FriendsIcon />} onClick={() => navigate('/friends')}>
          {!!referrals?.length && (
            <div className="text-[14px] font-medium opacity-50">{referrals.length} people</div>
          )}
        </SettingsItem>
        <SettingsItem name="Achievements" icon={<AchievementsIcon />} noChevron>
          <div className="py-0.5 px-2 bg-[#FF819833] rounded-full text-[14px] font-medium font-Onest text-accent backdrop-blur">
            soon
          </div>
        </SettingsItem>
        <SettingsItem
          name="Boosts store"
          icon={<LightningIcon />}
          onClick={() => navigate('/boosts')}
        />
        <SettingsItem
          name="Enter promocode"
          icon={<PromocodeIcon />}
          onClick={() => navigate('/profile/enter-code')}
        />
      </div>

      <div>
        {/* <SettingsItem name="What’s new" icon={<FlagIcon />} soon /> */}
        <SettingsItem
          name="Support"
          icon={<SupportIcon />}
          onClick={() => {
            postEvent('web_app_open_tg_link', {
              path_full: `/BrainsCoinSupportBot`,
            })
          }}
        />
        {isFeatureEnabled('dev') && (
          <>
            <SettingsItem
              name="get token [dev]"
              icon={<LightningIcon />}
              onClick={() => {
                const initDataRaw = retrieveLaunchParams()?.initDataRaw
                if (initDataRaw) {
                  navigator.clipboard.writeText(initDataRaw)
                  alert('Token copied to clipboard')
                } else {
                  alert('No initDataRaw found')
                }
              }}
            />
          </>
        )}
      </div>

      <div className="text-xs text-center text-[#fff]/20">
        v{__APP_VERSION__}&nbsp;
        {import.meta.env.MODE}
      </div>
    </div>
  )
}

function LightningIcon() {
  return (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.75"
        d="M3.37012 8.57539V13.3561C3.37012 13.9749 4.17357 14.2362 4.54983 13.7397L9.82825 6.77493C10.251 6.2171 9.84548 5.42459 9.13727 5.42459H6.62968L3.37012 8.57539Z"
        fill="#FF8198"
      />
      <path
        d="M6.62998 0.643879C6.62998 0.0250522 5.82653 -0.236208 5.45027 0.260261L0.171845 7.22507C-0.250919 7.7829 0.154612 8.57541 0.862827 8.57541H3.37041L6.62998 5.42461V0.643879Z"
        fill="#FF8198"
      />
    </svg>
  )
}
